import React from 'react';
import { useAuth } from '../../AuthContext';

import { auth } from '../../firebase'


function HomePage() {

  const { currentUser, logout } = useAuth();


  console.log(currentUser);


  function handleLogOut() {
    auth.signOut().then((v) => {
      logout();
    })

  }

  return (
    <div>
      <h3>Home Page</h3>

      <button onClick={handleLogOut}>Log Out</button>

    </div>


  )
}

export default HomePage