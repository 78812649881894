import React, { Component } from "react";

import { createReactEditorJS } from "react-editor-js";

import { EDITOR_JS_TOOLS } from "../tools";

import Undo from 'editorjs-undo';

const ReactEditorJS = createReactEditorJS();


const handleReady = (editor) => {
    new Undo({ editor });
};

class Editor extends Component {



    render() {
        console.log("rendering ", this.props.initalData)
        return (
            <ReactEditorJS
                onReady={handleReady}
                onInitialize={this.props.onInit}
                tools={EDITOR_JS_TOOLS}
                holder={this.props.id}
                defaultValue={this.props.initalData}
                placeholder={'Begin writing blog content here ✨'}
            />
        );
    }
}

export default Editor
