import React, { useState } from 'react';
import CategoryTag from './CategoryTag';
import './CSS/CategoryInputField.css'

function CategoryInputField(props) {

    const [category, setCategory] = useState('')


    const handleKeyUp = (e) => {
        if (e.keyCode == 13 && category.length > 0) {
            props.onCategoryAdd(category);
            setCategory('');
        }
    }


    return (
        <div className='my-4'>
            <div className="form-group">
                <label >Enter Categories</label>
                <input
                    ref={props.inputRef}

                    onKeyUp={handleKeyUp} type="text" className="form-control" placeholder="Categories"
                    value={category}
                    onChange={event => setCategory(event.target.value)} />
            </div>
            <div className='tag-container my-2'>
                {props.categories.map((val, index) => {
                    return <CategoryTag key={index} text={val}

                        onDelete={() => props.handleDelete(index)} />
                })}

            </div>
        </div>
    )
}

export default CategoryInputField