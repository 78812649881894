import React from 'react';
import './styles.css';

import Form from '../../components/common/Form';



function SignInPage() {

  return (
    <div className='container'>
    
      <Form />


    </div>
  )
}

export default SignInPage