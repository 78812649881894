// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvYsoJxaTp9WsbyWllI7mSKERnoyIa9cQ",
  authDomain: "data-science-classes.firebaseapp.com",
  projectId: "data-science-classes",
  storageBucket: "data-science-classes.appspot.com",
  messagingSenderId: "240350945353",
  appId: "1:240350945353:web:e865ad9a7e2f21fe911f98",
  measurementId: "G-Y378DMRCEG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

export { auth, db, storage }