import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Button from './Button';
import './styles.css';
import { useAuth } from '../../AuthContext';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export default function BasicTextFields() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {login } = useAuth();

    const handleSubmit = () => {
    

        signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
            //Signed In 
            login(userCredential.user)

        }).catch((error) => {
            const errorCode = error.code;
            if (errorCode === "auth/invalid-email") {
                console.log("INvalid email")
                toast.error('Please check the Email')

            } else if (errorCode === "auth/wrong-password") {
                toast.error('Please check the Password')
            }else if( errorCode === "auth/user-not-found"){
                toast.error('Please check the Email')
            }

            console.log(errorCode)


        })
    };

    const handleAction = () => {
        console.log(`Login with ${email} and ${password}`)
        handleSubmit();

    };

    return (
        <div className='h-100 d-flex align-items-center justify-content-center'>
            
            <div className='col-10 col-md-4'>
                <div className="heading-container">
                    <h3>
                        Login To Admin
                    </h3>
                </div>

                

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"

                >
                    <TextField className='text-field' id="email" type='email' label="Enter the Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    <TextField className='text-field' id="password" type='password' label="Enter the Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />

                    <Button className='login-button' handleAction={handleAction} />
                </Box>
            </div>
        </div>

    );
}