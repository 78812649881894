import React, { useState } from 'react'
import EmptyList from '../../components/common/empty_list/EmptyList';
import Header from '../../components/blog_home/header/Header';
import SearchBar from '../../components/blog_home/search_bar/SearchBar';
import BlogList from '../../components/blog_home/blog_list/BlogList';


function BlogPage() {

    const [blogsMetadata, setBlogsMetaData] = useState([]);

    const [searchKey, setSearchKey] = useState("");

    const [blogList, setBlogList] = useState([]);

    // Handle Submit 
    const handleSearchSubmit = event => {
        console.log("Called");
        event.preventDefault();
        handleSearchResults();
    }

    // Search for blogs by category
    const handleSearchResults = () => {
        console.log("Called");
        console.log(searchKey);
        const allBlogs = blogsMetadata;
        const filteredBlogs = allBlogs.filter(blog => blog.category.toLowerCase().includes(searchKey.toLowerCase().trim()));

        setBlogList(filteredBlogs);

    }

    const handleClearSearch = () => {
        setBlogList(blogsMetadata);
        setSearchKey('');

    }


    return (
        <div>


            <a href='/blog/add'>Add blog</a>

           

            {/* Blog List and Empty List */}
             <BlogList blogs={blogList} />

        </div>
    )
}

export default BlogPage