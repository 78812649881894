import React from 'react';
import './CSS/NavBar.css';
import { HashLink as Link } from 'react-router-hash-link';




function NavBar() {


    return (
        <>

            <div className="container-fluid nav-bg">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <nav className="navbar navbar-expand-lg navbar-dark nav-bg">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="#home">Admin</Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                            <Link className="nav-link" aria-current="page" to="/">Dashboard</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" aria-current="page" to="/blog">Blog</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contacts">Contacts</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className='nav-link' to="/profile">
                                                Profile
                                            </Link>
                                        </li>
                                        

                                    </ul>

                                </div>
                            </div>
                        </nav>
                    </div>
                </div>


            </div>





        </>
    )

}

export default NavBar

