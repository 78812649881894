import React, { Component } from 'react';
import { doc, getDoc, onSnapshot, startAfter } from "firebase/firestore";
import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";
import ContactCardItem from './ContactCardItem';
import InfiniteScroll from 'react-infinite-scroll-component';

export default class ContactDataList extends Component {

    constructor() {
        super()
        this.state = { list: [] };
    }


    componentDidMount() {
        this.getContacts();


    }

    getContacts() {
        let set = this
        //initiate first set

        const q = query(collection(db, 'formData'), orderBy('timestamp', 'desc'), limit(10))

        const contactSub = onSnapshot(q, (querySnapshot) => {

            const list = [];

            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1].data().timestamp;



            querySnapshot.docs.forEach(function (doc) {
                const { name, email, message, phone, subject } = doc.data();
                const timestamp = doc.data().timestamp.toDate().toLocaleString();

                const id = doc.id;



                list.push({ id, name, email, message, phone, subject, timestamp });

                // for (var i = 0; i < 10; i++) {
                //     list.push({ name, email, message, phone, subject });
                // }
            });

            this.setState({ list: list, last: lastVisible });


        })



    }


    fetchMoreContacts = async () => {
        const q = query(collection(db, 'formData'), orderBy('timestamp', 'desc'), startAfter(this.state.last), limit(10))

        var querySnapshot = await getDocs(q);

        const list = [];

        var lastDocRef = querySnapshot.docs[querySnapshot.docs.length - 1];

        if (lastDocRef){
            var lastVisible = lastDocRef.data().timestamp;
        }else{
            lastVisible = null;
        }
    
        






        querySnapshot.docs.forEach(function (doc) {
            const { name, email, message, phone, subject } = doc.data();

            const timestamp = doc.data().timestamp.toDate().toLocaleString();
            const id = doc.id;

            list.push({id, name, email, message, phone, subject, timestamp });
        });

        let updated_list = this.state.list.concat(list);
        this.setState({ list: updated_list, last: lastVisible });

    }

    render() {
        return (
            <InfiniteScroll
                dataLength={this.state.list.length}
                next={this.fetchMoreContacts}
                hasMore={true}
                loader={<span className='text-secondary'> loading</span>}

            >

                <div className="container-fluid p-5">

                    <div className="col-11, mx-auto">

                        <div className="row">

                            {this.state.list.map((value, index) => {
                                return <ContactCardItem key={index}
                                    data={value}
                                />
                            })}

                        </div>

                    </div>




                </div>

            </InfiniteScroll>

        )
    }
}

