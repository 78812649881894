import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/sign_in_page/SignInPage';
import { AuthProvider } from "./AuthContext";
import HomePage from './pages/home/HomePage';
import PrivateRoute from './PrivateRoute';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import ContactsPage from './pages/contacts/ContactsPage';
import NavBar from './components/NavBar';
import { ToastContainer } from 'react-toastify';
import BlogPage from './pages/blog/BlogPage';
import AddBlog from './pages/blog/AddBlog';
import EditBlog from './pages/blog/EditBlog';
import PageNotFound from './components/PageNotFound';


function App() {


  onAuthStateChanged(auth, (user) => {
    user === null ? sessionStorage.removeItem('user') : sessionStorage.setItem('user', user);
  })


  return (
    <>
      <BrowserRouter>
        <AuthProvider >


          <NavBar />

          <ToastContainer />

          <Routes>

            <Route path="/login" exact element={<SignInPage />} />
            <Route path='/' element={<PrivateRoute><HomePage /></PrivateRoute>}></Route>
            <Route path='/contacts' element={<PrivateRoute><ContactsPage /></PrivateRoute>}></Route>
            <Route path='/blog' element={<PrivateRoute><BlogPage /></PrivateRoute>}></Route>
            <Route path='/blog/add' element={<PrivateRoute><AddBlog /></PrivateRoute>}></Route>
            <Route path='/blog/edit/:id' element={<PrivateRoute><EditBlog /></PrivateRoute>}></Route>
            <Route path='/404' element={<PageNotFound />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>

        </AuthProvider>
      </BrowserRouter>
    </>

  );
}

// const Navigation = () => {
//   return (
//     <nav>
//       <Link to="/">Home</Link>
//       <Link to="/login">  Login</Link>
//     </nav>
//   );
// };

export default App;
