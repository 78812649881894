import { addDoc, collection, doc, getDoc, runTransaction, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";


async function fetchBlogById(id) {

    console.log('fetching blog ', id);

    const docRef = doc(db, 'blog', id);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log('blog exists ', docSnap.data());
        return docSnap.data();
    } else {
        console.log('no blog with', id);
        return null;
    }

}


function addNewBlog(data) {



    data['createdOn'] = serverTimestamp();
    data['updatedOn'] = serverTimestamp();

    var metaDataId = autoId();

    data['metaDataId'] = metaDataId;

    var blogMetaData = {
        id: metaDataId,
        blogId: data.id,
        title: data.title,
        excerpt: data.excerpt,
        slug: data.slug,
        featureImage: data.featureImage,
        featured: data.featured,
        lastUpdate: data.updatedOn,
        categories: data.categories,
        author: data.author,
        published: data.published

    }


        ;

    const batch = writeBatch(db);

    const metaDataRef = doc(db, 'blogMetaData', blogMetaData.id);

    batch.set(metaDataRef, blogMetaData);


    console.log("saving data", data, 'with meta data ', blogMetaData)
    var myBlogRef = doc(db, 'blog', data.id);

    batch.set(myBlogRef, data);

    return batch.commit();

}


function updateBlog(data) {

    data['updatedOn'] = serverTimestamp();

    var blogMetaData = {
        id: data.metaDataId,
        blogId: data.id,
        title: data.title,
        excerpt: data.excerpt,
        slug: data.slug,
        featureImage: data.featureImage,
        featured: data.featured,
        lastUpdate: data.updatedOn,
        categories: data.categories,
        author: data.author,
        published: data.published

    }

    const batch = writeBatch(db);

    console.log('HEre', blogMetaData)

    const metaDataRef = doc(db, 'blogMetaData', blogMetaData.id);

    batch.update(metaDataRef, blogMetaData);


    console.log("saving data", data, 'with meta data ', blogMetaData)
    var myBlogRef = doc(db, 'blog', data.id);

    batch.update(myBlogRef, data);

    return batch.commit();

}



function autoId() {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let autoId = ''

    for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length)
        )
    }
    return autoId
}

export { addNewBlog, autoId, updateBlog, fetchBlogById }