
import { deleteDoc, doc } from 'firebase/firestore'
import React from 'react'
import { toast } from 'react-toastify';
import { db } from '../firebase'


function ContactCardItem(props) {

    const onDelete = () => {
        const id = props.data.id.toString();

        deleteDoc(doc(db, "formData", id)).then(() => {
            toast.success(`Deleted ${props.data.name}`)
        })

    }

    return (
        <div className="col-md-6 col-sm-6 col-12 col-lg-4 mx-auto m-2">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{props.data.name}</h5>
                    <h6 className="card-subtitle mb-2 text-info">{props.data.email}</h6>
                    <h6 className="card-subtitle mb-2 text-info">{props.data.phone}</h6>
                    <h6 className="card-subtitle mb-2 text-muted">{props.data.subject}</h6>
                    <p className='text-muted'>{props.data.timestamp}</p>
                    <p className="card-text">{props.data.message}</p>
                    <button type="button" className="btn btn-danger" onClick={onDelete}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default ContactCardItem