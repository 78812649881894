import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CategoryInputField from '../../components/CategoryInputField'
import Editor from '../../components/Editor';
import { addNewBlog, autoId } from '../../service/firestore_service';
import './AddBlog.css';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { uploadFile } from '../../service/storage_service';


function AddBlog() {


    const navigate = useNavigate();


    const editorCore = React.useRef(null);

    const titleInput = React.createRef();
    const featureImageInput = React.createRef();
    const slugInput = React.createRef();
    const excerptInput = React.createRef();
    const categoryInput = React.createRef();

    const [featureImage, setFeatureImage] = useState(null);
    const [content, setContent] = useState({});
    const [title, setTitle] = useState('');
    const [featured, setFeatured] = useState(false);
    const [slug, setSlug] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [progress, setProgress] = useState(0);

    const [categoriesArray, setcategoriesArray] = useState([]);


    const author = {
        name: 'Saumya Kumar',
        profileImage: 'https://picsum.photos/200'
    }




    function handleImageSelection(e) {
        console.log(e.target.files);
        var imageUrl = URL.createObjectURL(e.target.files[0]);
        setFeatureImage(imageUrl);
        setProgress(1)
        uploadFile(e.target.files[0]).then((url) => {
            console.log('image url', url)
            setFeatureImage(url);
            setProgress(0)
        }).catch((e) => {
            setProgress(0)
            setFeatureImage(null)
        });



    }



    const handleInitialize = React.useCallback((instance) => {
        editorCore.current = instance
    }, [])



    const handleSave = (event, publish) => {
        event.preventDefault();

        editorCore.current.save().then((editorData) => {
            setContent(editorData);

            var data = gatherBlogData(publish, editorData);

            if (publish) {
                if (!validateFields(data)) {
                    // validate all field before publish
                    return;
                }
            }

            // at least have title to save

            if (title.length > 0) {


                addNewBlog(data)
                    .then(() => {
                        if (!publish) {
                            navigate(`/blog/edit/${data.id}`, { state: data });
                        } else {
                            navigate('/blog')
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error("Some error occurred. 🚫");
                    });
            } else {
                toast.error("Provide Title");
                titleInput.current.focus();

            }
        });





    }



    const validateFields = (data) => {
        console.log("validating data", data);
        if (data.title.length < 1) {
            titleInput.current.focus();
            toast.error('Please Provide title', { autoClose: 3000 });
            return false;
        } else if (data.categories.length < 1) {
            categoryInput.current.focus();
            toast.erdata.ror('Please Provide a category', { autoClose: 3000 });
            return false;
        } else if (data.slug.length < 1) {
            slugInput.current.focus();
            toast.error('Please Provide slug', { autoClose: 3000 });
            return false;
        } else if (data.excerpt.length < 1) {
            excerptInput.current.focus();
            toast.error('Please Provide Excerpt', { autoClose: 3000 });
            return false;
        } else if (data.featureImage == null) {
            featureImageInput.current.focus();
            toast.error('Please Provide feature image', { autoClose: 3000 });
            return false;
        }
        else if (data.content.blocks.length < 1) {
            toast.error('Please Provide Content');
            return false;
        } else {
            return true
        }

    }




    const gatherBlogData = (publish, savedData) => {
        var id = autoId();

        return {
            id: id,
            published: publish,
            title: title,
            featured: featured,
            author: author,
            categories: categoriesArray,
            slug: slug,
            excerpt: excerpt,
            featureImage: featureImage,
            content: savedData ? savedData : content

        }
    }

    const handleCategoryAdd = (category) => {
        setcategoriesArray([...categoriesArray, category])
    }

    const handleDelete = (index) => {
        console.log('handle delete claled', index)
        categoriesArray.splice(index, 1);
        setcategoriesArray([...categoriesArray]);

    }



    return (
        <div className='container-fluid '>

            <div className='col-11 col-md-8 col-lg-6 mx-auto my-5'>



                <div className="form-group">
                    <label>Blog Title</label>
                    <input
                        ref={titleInput}
                        type="text" className="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Title" required
                        value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>


                <CategoryInputField
                    inputRef={categoryInput}
                    categories={categoriesArray}
                    onCategoryAdd={handleCategoryAdd}
                    handleDelete={handleDelete} />

                <div className="form-group my-2">
                    <label>Slug</label>
                    <input type="text"
                        ref={slugInput}
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        className="form-control" id="exampleInputEmail1" aria-describedby="slugHelp" placeholder="Slug (ex:- prints-in-python, what-is-saas)" required />
                    <small id="slugHelp" className="form-text text-muted">A url friendly text</small>
                </div>

                <div className="form-group my-2">
                    <label>Excerpt</label>
                    <textarea type="text"
                        ref={excerptInput}
                        value={excerpt}
                        onChange={(e) => setExcerpt(e.target.value)}
                        className="form-control " id="exampleInputEmail1" aria-describedby="excerptHelp" placeholder="Excerpt" required />
                    <small id="excerptHelp" className="form-text text-muted">Small description ofcontent</small>
                </div>


                <div className="form-group">
                    <label >Feature Image</label>

                    <div>
                        <Box sx={{ width: '100%' }}>
                            {progress ? <LinearProgress /> : null
                            }
                        </Box>

                        <div className='image-picker-container d-flex align-items-center justify-content-center'>



                            {featureImage ? <img
                                className='blog-image' src={featureImage} /> : <input ref={featureImageInput}
                                    type="file" accept='image/*' onChange={handleImageSelection} className="btn btn-outline-secondary" />}

                        </div>

                    </div>


                    {featureImage ? <button

                        type="button" className="btn btn-outline-danger my-2 " onClick={() => setFeatureImage(null)}>Remove Image</button> : null}

                </div>

                <div className="my-3 content-container">


                    <Editor
                        onInit={handleInitialize}
                        id={'editorId'}
                        initalData={{ blocks: [] }}

                    />

                    <div id="editor"></div>

                </div>

                <h6>Featured</h6>
                <div className="form-check-inline">


                    <input className="form-check-input"
                        checked={featured}
                        type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                        onChange={(e) => setFeatured(true)} value={true} />
                    <label className="form-check-label">
                        True
                    </label>
                </div>
                <div className="form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={!featured}
                        onChange={(e) => setFeatured(false)} value={false} />
                    <label className="form-check-label" >
                        False
                    </label>
                </div>



                <div className="form-group my-3">
                    <label >Author</label>

                    <div className="card author-card" >
                        <div className="row">
                            <div className="col-4 d-flex align-items-center justify-content-center">
                                <img src={author.profileImage} className="img-fluid rounded avatar-image" alt="..." />
                            </div>
                            <div className="col-8">
                                <div className="card-body">
                                    <h5 className="card-title">{author.name}</h5>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <button className="btn btn-primary m-2" onClick={(e) => handleSave(e, false)}>Save</button>
                <button className="btn btn-success m-2" onClick={(e) => handleSave(e, true)}>Save and Publish</button>


            </div>






        </div>
    )
}

export default AddBlog