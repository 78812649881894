import React from 'react'
import ContactDataList from '../../components/ContactDataList'


function ContactsPage() {
    return (
        <div>

            <ContactDataList />




        </div>


    )
}

export default ContactsPage