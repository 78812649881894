import React from 'react';
import './CSS/CategoryTag.css'

function CategoryTag(props) {
    return (
        <div className='tag'><p className='tag-delete' onClick={props.onDelete}>X</p>

            <p className='tag-text'>{props.text}</p></div>
    )
}

export default CategoryTag