import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/PageNotFound.css';

const PageNotFound = () => {
    return (
        <div id="wrapper">
            <img src="/assets/images/404_image.png" />
            <div id="info">
                <h3>This page could not be found</h3>
                <Link to="/">Go to Home </Link>
            </div>
        </div >
    )
}

export default PageNotFound