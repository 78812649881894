import React, { Component } from 'react';
import './styles.css';
import BlogItem from './blog_item/BlogItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { doc, getDoc, onSnapshot, startAfter } from "firebase/firestore";
import { db } from '../../../firebase';
import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";

export default class BlogList extends Component {


  constructor() {
    super()
    this.state = { list: [] };
  }


  componentDidMount() {
    this.getBlogsMetaData();


  }

  getBlogsMetaData() {


    const q = query(
      collection(db, 'blogMetaData'),
      orderBy('lastUpdate', 'desc'),
      limit(10))

    const contactSub = onSnapshot(q, (querySnapshot) => {

      const list = [];

      var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1].data().lastUpdate;


      querySnapshot.docs.forEach(function (doc) {
        const data = doc.data();


        list.push(data);


      });

      this.setState({ list: list, last: lastVisible });


    })



  }


  fetchMoreContacts = async () => {
    console.log('last', this.state.last)
    const q = query(
      collection(db, 'blogMetaData'),
      orderBy('lastUpdate', 'desc'),
      startAfter(this.state.last), limit(10))

    var querySnapshot = await getDocs(q);

    const list = [];

    var lastDocRef = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (lastDocRef) {
      var lastVisible = lastDocRef.data().lastUpdate;
    } else {
      lastVisible = null;
    }

    querySnapshot.docs.forEach(function (doc) {
      const data = doc.data();



      list.push(data);
    });

    let updated_list = this.state.list.concat(list);
    this.setState({ list: updated_list, last: lastVisible });

  }

  render() {
    return (

      <InfiniteScroll
        dataLength={this.state.list.length}
        next={this.fetchMoreContacts}
        hasMore={true}
        loader={<span className='text-secondary'> loading</span>}

      >

        <div className="container-fluid">

          <div className="col-11, mx-auto">

            <div className="row">
              {this.state.list.map((blog) => (
                <BlogItem key={blog.id} blog={blog} />
              ))}
            </div>

          </div>




        </div>

      </InfiniteScroll>

    )
  }


}




{/* <div className='blogList-wrap'>
      
    </div> */}